var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MainLayout',{scopedSlots:_vm._u([{key:"navbar-title",fn:function(){return [_vm._v(_vm._s(`${_vm.sourceObject.phone_number}`)+" ")]},proxy:true},{key:"navbar-btn-wrap",fn:function(){return [(_vm.urlQuery && _vm.urlQuery.id)?_c('router-link',{staticClass:"btn btn-edit btn_color_grey",attrs:{"to":`editParent/` + _vm.urlQuery.id,"append":""}},[_vm._v("Редактировать ")]):_vm._e(),_c('DeleteAgreementPopup',{attrs:{"name":"Опекуна","title":_vm.sourceObject.phone_number ? _vm.sourceObject.phone_number : '',"description":"Пользователь потеряет доступ к объекту. Оплаченные доступы будут аннулированы.","delete-function":_vm.deleteParent,"agreeDescription":"Аннулировать платный доступ."}}),(
        _vm.$store.getters.getCurrentRole == 70 &&
          !(_vm.sourceObject && _vm.sourceObject.is_active)
      )?_c('UnBlockPopup',{attrs:{"name":"Пользователя","title":_vm.sourceObject.phone_number,"un-block-function":_vm.unBanUser}}):_vm._e(),(
        _vm.$store.getters.getCurrentRole == 70 &&
          _vm.sourceObject &&
          _vm.sourceObject.is_active
      )?_c('BlockPopup',{attrs:{"name":"Пользователя","title":_vm.sourceObject.phone_number,"block-function":_vm.banUser}}):_vm._e()]},proxy:true}])},[_c('SlideLayout',{attrs:{"tabs":_vm.slideTabs},scopedSlots:_vm._u([{key:"info",fn:function(){return [_c('div',{staticClass:"keep__title"},[_c('p',{staticClass:"keep__text"},[_vm._v("Общая информация")])]),(_vm.sourceObject)?_c('BasicTableWithoutHeader',{attrs:{"table-data":_vm.getDataRequisites},scopedSlots:_vm._u([{key:"item.3.value",fn:function({ value }){return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(value)+" "),_c('div',{staticClass:"ml-auto"},[(
                  _vm.currentRole == 31 || _vm.currentRole == 60 || _vm.currentRole == 70
                )?[_c('div',[_c('v-btn',{staticClass:"btn btn-green",attrs:{"type":"submit","disabled":!_vm.hasAccessToRestore},on:{"click":_vm.restoreUserAccesses}},[_vm._v(" Восстановить доступ ")])],1)]:_vm._e()],2)])]}},{key:"item.5.value",fn:function({ value }){return [_c('div',[_c('span',{style:({
                color:
                  value.actual_accesses.length > 0
                    ? '#95C23D !important'
                    : '#EB5C6D !important'
              })},[_vm._v(" "+_vm._s(value.actual_accesses.length > 0 ? (() => { let access = value.actual_accesses; if (access.length >= 2) { access = access.find( item => item.access_type === "app" ); if (!access) { return "Не оплачено"; } } else if (access.length === 0) { return "Не оплачено"; } if (access.access_by === "paid") { return ( "Оплачено до " + _vm.moment(access.date_end * 1000).format("DD.MM.YYYY") ); } else if (access.access_by === "trial") { return ( "Пробный, " + _vm.moment(access.date_end * 1000).format("DD.MM.YYYY") ); } else if (access.access_by === "unpaid") { return "Бесплатный доступ"; } })() : "Не оплачено")+" ")])])]}},(_vm.currentRole == 31 || _vm.currentRole == 60 || _vm.currentRole == 70)?{key:"item.4.value",fn:function({}){return [_c('div',{staticClass:"d-flex"},[_c('ValidationInputField',{staticClass:"mr-4 d-flex",attrs:{"mask":"###.#####","disabled":_vm.input_disabled || !_vm.user_payments == 'input-requied'},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticStyle:{"border-radius":"50px","color":"#ffffff","padding":"5px 15px"},style:({
                  background:
                    _vm.user_payments === 'paid'
                      ? '#95C23D'
                      : _vm.user_payments === 'not-paid'
                      ? '#EB5C6D'
                      : '#6D778E'
                })},[_c('p',{staticClass:"text-align_center"},[_vm._v(" "+_vm._s(_vm.user_payments === "paid" ? "Оплачена" : _vm.user_payments === "not-paid" ? "Не оплачена" : "Ожидает ввода карты")+" ")])])]),_c('div',{staticClass:"ml-auto"},[(
                  _vm.currentRole == 31 || _vm.currentRole == 60 || _vm.currentRole == 70
                )?[_c('v-btn',{staticClass:"btn btn-green",attrs:{"type":"submit","disabled":_vm.input_disabled || _vm.code_isEdit == false},on:{"click":_vm.editParent}},[_vm._v(" Сохранить ")])]:_vm._e()],2)],1)]}}:null],null,true)}):_vm._e()]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }